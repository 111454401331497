import { gql } from '@apollo/client';
import accessoryV4Fragment from 'apollo/fragments/accessoryV4';

const getAccessoryVariantsQuery = gql`
  query accessoryVariantsV4($variantIds: [String]!) {
    accessoryVariantsV4(variantIds: $variantIds) {
      ...AccessoryV4
    }
  }
  ${accessoryV4Fragment}
`;

export default getAccessoryVariantsQuery;
