import {
  DEVICE_BRAND_LIST_PAGE,
  DEVICE_PRODUCT_LIST_PAGE,
  DEVICE_PRODUCT_PAGE,
  buildRoute,
  getDeviceTypeUrlSegment,
} from 'constants/urls';
import stringToSlug from './url/stringToSlug';

const generateDeviceTypeUrl = (type) =>
  `${buildRoute(DEVICE_PRODUCT_LIST_PAGE, stringToSlug(getDeviceTypeUrlSegment(type)))}`;

const generateDeviceBrandUrl = (type, brand) =>
  `${buildRoute(DEVICE_BRAND_LIST_PAGE, stringToSlug(getDeviceTypeUrlSegment(type)), stringToSlug(brand))}`;

const generateDeviceUrl = (type, brand, slug) =>
  `${buildRoute(DEVICE_PRODUCT_PAGE, stringToSlug(getDeviceTypeUrlSegment(type)), stringToSlug(brand), slug)}`;

export { generateDeviceUrl, generateDeviceBrandUrl, generateDeviceTypeUrl };
