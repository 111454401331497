import { gql } from '@apollo/client';

const accessoryV4Fragment = gql`
  fragment AccessoryV4 on AccessoryV4 {
    id
    name
    brand
    categories
    slug
    content {
      shortDescriptionHeader
      shortDescription
      longDescription
      technicalSpecifications
      highlight
      uniqueSellingPoints
      media {
        type
        url
        title
        description
      }
      metaDataTitle
      metaDataDescription
      specificationHighlights {
        title
        icon
      }
      freeTextHighlight {
        line1
        line2
        line3
        backgroundColour
      }
    }
    variants {
      name
      id
      offering {
        id
        price {
          originalPrice
          sellingPrice
          reductionAmount
        }
        paymentPlanOptions {
          upfrontPlan {
            amount
            type
            available
          }
          installmentPlans {
            periodAmount
            duration
            originalPeriodAmount
            type
          }
          recurringPlan {
            periodAmount
            bindingPeriod
            noticePeriod
            type
          }
        }
        salesEnd
        highlightType
        offeringHighlight {
          highlightType
          highlightReductionType
          highlightPercentage
          highlightAmount
        }
      }
      vendorColourName
      size
      content {
        colour {
          primaryHexCode
          secondaryHexCode
        }
        images {
          url
          title
          description
        }
      }
      expectedDelivery {
        dateInterval {
          to
          from
        }
        deliveryDate
      }
      stockLevel
      eanCode
      filterMatch
    }
  }
`;

export default accessoryV4Fragment;
