export default function stringToSlug(str) {
  return (
    str
      // Replace 'å' with 'aa' using a case-insensitive regular expression.
      .replace(/\xE5/gi, 'aa')
      // Replace 'æ' with 'ae' using a case-insensitive regular expression.
      .replace(/\xE6/gi, 'ae')
      // Replace 'ø' with 'o' using a case-insensitive regular expression.
      .replace(/\xF8/gi, 'o')
      // The normalize method returns the Unicode Normalization Form of a given string.
      // 'NFKD' form will convert characters like 'á' into 'a' and a combining accent character.
      .normalize('NFKD')
      // Remove diacritics (accents) from each letter using a regular expression.
      .replace(/\p{Diacritic}/gu, '')
      // Replace one or more non-word characters (all characters not a-z, A-Z, 0-9) or underscores with a hyphen.
      .replace(/[\W_]+/g, '-')
      // Convert all characters to lower case.
      .toLowerCase()
      // Remove hyphens from the beginning and end of the string.
      .replace(/^-+|-+$/g, '')
  );
}
