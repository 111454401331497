import { Splash } from '@3dk/3style';
import PropTypes from 'prop-types';
import { getSplashColorFromName } from 'app/routes/pageContainer/page/Entry/Colors/Colors';

const makeClasses = (line3) => ({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  line1: {
    fontSize: line3 ? '22px' : '15px',
    fontWeight: 'bold',
  },
  line2: {
    fontSize: line3 ? '13px' : '15px',
    fontWeight: line3 ? 'normal' : 'bold',
  },
  line3: {
    fontSize: '11px',
  },
});

const FreeTextSplash = ({ line1, line2, line3, backgroundColour, ...rest }) => {
  const classes = makeClasses(line3);

  return (
    <Splash backgroundColor={getSplashColorFromName(backgroundColour)} {...rest}>
      <div css={classes.textContainer}>
        {line1 && <div css={classes.line1}>{line1}</div>}
        {line2 && <div css={classes.line2}>{line2}</div>}
        {line3 && <div css={classes.line3}>{line3}</div>}
      </div>
    </Splash>
  );
};

FreeTextSplash.propTypes = {
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
  line3: PropTypes.string,
  backgroundColour: PropTypes.oneOf(['Blue', 'Orange', 'Green', 'Red', 'Yellow', 'Black', 'White']),
};

FreeTextSplash.defaultProps = {
  line2: '',
  line3: '',
  backgroundColour: 'Blue',
};

export default FreeTextSplash;
