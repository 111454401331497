import { gql } from '@apollo/client';
import legacyDeviceFragment from '../fragments/legacyDevice';

const getDevicesForProductSliderQuery = gql`
  query getDevicesForProductSlider($productIds: [String]) {
    devicesOnListPage(productIds: $productIds) {
      ...LegacyDeviceFragment
    }
  }
  ${legacyDeviceFragment}
`;

export default getDevicesForProductSliderQuery;
