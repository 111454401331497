import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ProductSlider, Box, Headline } from '@3dk/3style';
import getAccessoryVariantsQuery from '../../../../../apollo/queries/getAccessoryVariants';
import getDevicesForProductSliderQuery from '../../../../../apollo/queries/getDevicesOnListPage';
import getLongestInstallmentOption from '../../../../../utils/accessoryHelpers/installmentOptions';
import ProductCardWrapper from '../../../../../components/ProductCardWrapper';
import dataLayer from '../../../../../utils/dataLayer/dataLayer';
import LinkResolver from '../../../../../components/LinkResolver';
import linkPropType from '../../../../../utils/propTypes/link';

const classes = {
  root: {
    marginTop: '40px',
  },
  productSliderWrapper: {
    margin: '0 -1.5rem',
  },
  cardWrapper: {
    padding: '0 1rem',
  },
  link: (theme) => ({
    display: 'inline-block',
    color: theme.palette.TYPOGRAPHY_BLACK,
    marginTop: '10px',
  }),
};

const mapProductsToCardData = (variantIds, products, isLegacyDevice) =>
  (variantIds || [])
    .map((variantId) => {
      const product = (products || []).find((item) => item.variants.some((v) => v.id === variantId));

      if (!product) {
        return null;
      }
      const variant = product.variants.find((v) => v.id === variantId);
      const installmentOption = getLongestInstallmentOption(variant?.offering?.paymentPlanOptions.installmentPlans);
      const media = variant.content?.images?.[0];
      return { product, variant, installmentOption, media, isLegacyDevice };
    })
    .filter(Boolean);

const ProductSliderEntry = (props) => {
  const { anchor, header, subheaderLink, productVariants } = props;

  const accessoryVariantIds = productVariants
    .filter((product) => !product.isLegacyDevice)
    .map((product) => product.variantId);

  const legacyVariantIds = productVariants
    .filter((product) => product.isLegacyDevice)
    .map((product) => product.variantId);

  const { data: accessoryData } = useQuery(getAccessoryVariantsQuery, {
    variables: { variantIds: accessoryVariantIds },
  });

  const { data: legacyData } = useQuery(getDevicesForProductSliderQuery, {
    variables: { productIds: legacyVariantIds },
  });

  const accessoryCardData = mapProductsToCardData(accessoryVariantIds, accessoryData?.accessoryVariantsV4);
  const legacyCardData = mapProductsToCardData(legacyVariantIds, legacyData?.devicesOnListPage, true);

  const allCards = accessoryCardData.concat(legacyCardData);
  const allCardsSorted = productVariants
    .map((product) => allCards.find((card) => product.variantId === card.variant.id))
    .filter(Boolean);

  return (
    <div css={classes.root}>
      <section id={anchor || 'product-slider'}>
        {header && <Headline> {header} </Headline>}
        {subheaderLink && (
          <LinkResolver {...subheaderLink} css={classes.link}>
            {subheaderLink.text}
          </LinkResolver>
        )}
        {allCardsSorted?.length > 0 && (
          <Box css={classes.productSliderWrapper}>
            <ProductSlider>
              {allCardsSorted.map((card, index) => (
                <Box key={`product-${card.product.id || card.product.slug}`} css={classes.cardWrapper}>
                  <ProductCardWrapper
                    card={card}
                    isDevice={card.isLegacyDevice}
                    onClickEvent={() => dataLayer.productListClickEvent(card.product, index, header)}
                    defaultVariant={card.product.variants[0]}
                  />
                </Box>
              ))}
            </ProductSlider>
          </Box>
        )}
      </section>
    </div>
  );
};

ProductSliderEntry.defaultProps = {
  anchor: '',
  header: '',
  subheaderLink: null,
};

ProductSliderEntry.propTypes = {
  anchor: PropTypes.string,
  header: PropTypes.string,
  subheaderLink: PropTypes.shape(linkPropType),
  productVariants: PropTypes.arrayOf(
    PropTypes.shape({
      variantId: PropTypes.string.isRequired,
      isLegacyDevice: PropTypes.bool,
    }),
  ).isRequired,
};

export default ProductSliderEntry;
