import PropTypes from 'prop-types';
import { ProductCard, Splash } from '@3dk/3style';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { getSplashColorFromName } from 'app/routes/pageContainer/page/Entry/Colors/Colors';
import { generateDeviceUrl } from 'utils/generateDeviceUrls';
import PriceSplash from './PriceSplash';
import collectVariantColors from './productList/collectVariantColors';
import {
  ACCESSORY_TYPE,
  MEDIA_TYPE,
  PAYMENT_PLAN_OPTIONS_TYPE,
  ACCESSORY_VARIANT_TYPE,
} from '../utils/propTypes/accessories';
import { DEVICE_TYPE, DEVICE_VARIANT_TYPE } from '../utils/propTypes/device';
import extractInstallmentPeriod from '../utils/extractInstallmentPeriod';
import FreeTextSplash from './FreeTextSplash';
import { AGENT_PRODUCT_PAGE, PRODUCT_PAGE } from '../constants/urls';
import generateAccessoryUrl from '../utils/generateAccessoryUrl';
import useProductCategorySlugs from '../utils/hooks/useProductCategorySlugs';

const classes = {
  // TODO (NOV-2567): Align splash with product page
  deviceSplashText: (theme) => ({
    textAlign: 'center',
    fontSize: '13px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  }),
  link: {
    textDecoration: 'none',
  },
};

const ProductCardWrapper = ({
  card: { product, variant, installmentOption, media },
  onClickEvent,
  isDevice,
  defaultVariant,
  ...rest
}) => {
  const location = useLocation();
  const { productCategorySlugs } = useProductCategorySlugs();
  const isAgent = location.pathname.match(/^\/agent/);
  const { offering } = variant || {};
  const { offeringHighlight, salesEnd, price } = offering || {};
  const { name, deviceSplashText, deviceSplashColor, variants, sticker, type, brand, slug, slogan, categories } =
    product || {};

  const { freeTextHighlight } = product?.content || {};
  const { line1, line2, line3, backgroundColour } = freeTextHighlight || {};
  const isDefaultVariant = defaultVariant?.id === variant.id;
  let pathWithQuery;
  if (isDevice) {
    pathWithQuery = generateDeviceUrl(type, brand, slug);
  } else {
    pathWithQuery = generateAccessoryUrl(
      isAgent ? AGENT_PRODUCT_PAGE : PRODUCT_PAGE,
      slug,
      productCategorySlugs,
      categories,
      isDefaultVariant ? variant.id : null,
    );
  }

  const getAccessorySplash = () => {
    if (offeringHighlight) {
      return (
        <PriceSplash
          type={offeringHighlight.highlightType}
          salesEnd={salesEnd}
          originalPrice={price?.originalPrice}
          reductionType={offeringHighlight.highlightReductionType}
          reductionAmount={offeringHighlight.highlightAmount}
          reductionPercentage={offeringHighlight.highlightPercentage}
        />
      );
    }
    if (freeTextHighlight) {
      return <FreeTextSplash line1={line1} line2={line2} line3={line3} backgroundColour={backgroundColour} />;
    }
    return null;
  };

  return (
    <Link css={classes.link} to={pathWithQuery} onClick={onClickEvent}>
      <ProductCard
        buttonText={t('Product_List.Product_Card.Buy_Button')}
        minimumPriceText={t('Product_List.Product_Card.Minimum_Price_Text')}
        caption={name}
        image={
          media && {
            url: media.url ?? '',
            alt: media.description ?? '',
            title: media.title ?? '',
          }
        }
        splash={
          isDevice
            ? deviceSplashText && (
                <Splash backgroundColor={getSplashColorFromName(deviceSplashColor)}>
                  <div css={classes.deviceSplashText}>{deviceSplashText}</div>
                </Splash>
              )
            : getAccessorySplash()
        }
        sticker={sticker}
        price={{
          installment: {
            sellingPrice: installmentOption?.periodAmount,
            originalPrice: installmentOption?.originalPeriodAmount,
            period: parseInt(extractInstallmentPeriod(installmentOption?.duration), 10),
          },
          upfront: {
            sellingPrice: price?.sellingPrice,
            minimumPrice: price?.minimumPrice,
            originalPrice: price?.originalPrice,
          },
        }}
        colors={collectVariantColors(variants)}
        greyOut={variants.every(
          (item) =>
            !item.offering.paymentPlanOptions.upfrontPlan.available &&
            item.offering.paymentPlanOptions.installmentPlans.length === 0,
        )}
        slogan={slogan}
        {...rest}
      />
    </Link>
  );
};

ProductCardWrapper.propTypes = {
  card: PropTypes.shape({
    product: PropTypes.oneOfType([ACCESSORY_TYPE, DEVICE_TYPE]),
    variant: PropTypes.oneOfType([ACCESSORY_VARIANT_TYPE, DEVICE_VARIANT_TYPE]),
    installmentOption: PAYMENT_PLAN_OPTIONS_TYPE,
    media: MEDIA_TYPE,
  }).isRequired,
  onClickEvent: PropTypes.func.isRequired,
  isDevice: PropTypes.bool,
  defaultVariant: PropTypes.oneOfType([ACCESSORY_VARIANT_TYPE, DEVICE_VARIANT_TYPE]).isRequired,
};

ProductCardWrapper.defaultProps = {
  isDevice: false,
};

export default ProductCardWrapper;
