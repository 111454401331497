const collectVariantColors = (variants) => {
  const colors = [];
  const uniqueColors = [];
  if (variants) {
    for (let i = 0; i < variants.length; i += 1) {
      if (variants[i]?.vendorColourName && !uniqueColors.includes(variants[i]?.vendorColourName)) {
        uniqueColors.push(variants[i]?.vendorColourName);
        const primaryColor = variants[i]?.content?.colour?.primaryHexCode;
        const secondaryColor = variants[i]?.content?.colour?.secondaryHexCode;
        colors.push({
          primary: primaryColor || null,
          secondary: secondaryColor || null,
        });
      }
    }
  }
  return colors;
};

export default collectVariantColors;
