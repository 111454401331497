import { gql } from '@apollo/client';

const legacyDeviceFragment = gql`
  fragment LegacyDeviceFragment on LegacyListPageDevice {
    id
    name
    brand
    type
    slug
    onSale
    deviceSplashText
    deviceSplashColor
    sticker
    slogan
    variants {
      name
      id
      color
      size
      vendorColourName
      offering {
        id
        price {
          sellingPrice
          minimumPrice
          reductionAmount
        }
        paymentPlanOptions {
          upfrontPlan {
            available
          }
          installmentPlans {
            periodAmount
            duration
            originalPeriodAmount
          }
        }
      }
      content {
        images {
          url
          title
          description
        }
      }
    }
  }
`;

export default legacyDeviceFragment;
